import {Component} from '@angular/core';
import {BridgeService, DeviceType, NativeMessage} from './bridge.service';
import {WindowRef} from './window.ref';

const AppStoreUrl = "https://itunes.apple.com/us/app/brightsidebenefit/id1250624924?mt=8";
const PlayStoreUrl = "https://play.google.com/store/apps/details?id=com.androidapp.gobrightside.mobile";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  updateSupported:boolean = false;
  deviceType:DeviceType;
  storeName:string = "Store";

  constructor(private bridge: BridgeService, private win: WindowRef) {

    this.deviceType = bridge.getDeviceType();
    if ( this.deviceType === DeviceType.Android) {
      this.storeName = "Google Play";
    }
    else if ( this.deviceType === DeviceType.iOS) {
      this.storeName = "App Store";
    }
    this.updateSupported = bridge.showUpdateApp();
  }

  updateApp() {
    this.bridge.postMessage(new NativeMessage("UPDATE_APP"));
    setTimeout(() => {
      this.openAppStore();
    },1500);
  }

  openAppStore() {
    if ( this.deviceType === DeviceType.iOS ) {
      this.win.nativeWindow.location.href = AppStoreUrl;
    }
    else if ( this.deviceType === DeviceType.Android) {
      this.win.nativeWindow.location.href = PlayStoreUrl;
    }
  }
}
