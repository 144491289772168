<bw-theme theme="white">
  <div class="mobile-layout flex-column center">
    <div class="mobile-layout-logo vspace-32">
      <img src="../assets/brightsideLockup.svg" alt="Brightside logo" width="124" class="vspace-8">
    </div>
    <div class="mobile-layout-graphic vspace-24 flex-item-rigid">
      <img src="../assets/Illustration-iOS-LandingPage.svg" alt="brightside welcome graphic, a guy with a key" style="max-width: fit-content;">
    </div>
    <div class="mobile-layout-content flex-item-flexible vspace-24"  style="width: 260px; margin: auto;">
      <h2>Update required</h2>
      <br/>
      <p textStyle="Body2">Please install the latest version of the Brightside app to get the best and most secure experience.</p>
      <!-- Must be hidden, but required for email verification -->
    </div>
    <ng-container>
      <div class="mobile-layout-footer flex-item-rigid" >
        <button bw-button fluidWidth (click)="updateApp()">Update app</button>
      </div>
    </ng-container>
  </div>
</bw-theme>
