import { Injectable } from '@angular/core';
import {WindowRef} from './window.ref';

export interface InjectedData {
  authToken: string;
  version: string;
  injectedTs?: Date;
}

export class NativeMessage {
  event: string;
  data: object = {};

  constructor(event: string, data?: object) {
    this.event = event;
    this.data = data;
  }

  stringify(): string {
    return JSON.stringify(this as object);
  }

  toJson(): object {
    return this as object;
  }
}

export enum DeviceType {
  iOS,
  Browser,
  Android,
  Unknown
}

declare let ga: Function;
// defined in the android app
// webview.addJavascriptInterface(new JavascriptEventHandler(),"jsHandler");
declare var jsHandler: any;
declare var Injected: InjectedData;

@Injectable({
  providedIn: 'root'
})
export class BridgeService {

  private deviceType: DeviceType = DeviceType.Unknown;

  constructor(private win: WindowRef) {
    this.discoverDevice();
  }

  public showUpdateApp(): boolean {
    return this.getDeviceVersion() >= "1.22.0";
  }

  public getDeviceType(): DeviceType {
    return this.deviceType;
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public getDeviceVersion(): string {
    if (typeof Injected === "object" && Injected.version) {
      return Injected.version;
    }

    const deviceType = this.getDeviceType();
    if (deviceType === DeviceType.Android || deviceType === DeviceType.iOS) {
      const versionCookie = this.getCookie("version");
      if (versionCookie) {
        return versionCookie;
      } else {
        return this.win.nativeWindow.localStorage["version"];
      }
    } else {
      return null;
    }
  }

  postMessage(message: NativeMessage): boolean {
    console.log("sending message:", message.stringify());
    if (message.data && message.data["parameters"] && message.data["parameters"].custom) {
      message.data["parameters"].custom = JSON.stringify(message.data["parameters"].custom);
    }
    if (this.deviceType === DeviceType.Unknown) {
      this.discoverDevice();
    }
    if (this.deviceType === DeviceType.Android) {
      jsHandler.postMessage(message.stringify());
      return true;
    } else if (this.deviceType === DeviceType.iOS) {
      this.win.nativeWindow.webkit.messageHandlers.buttonClicked.postMessage(message);
      return true;
    } else {
      return false;
    }
  }

  public discoverDevice(): void {
    if (typeof jsHandler !== "undefined") {
      this.deviceType = DeviceType.Android;
    } else if (
      this.win.nativeWindow.webkit &&
      typeof this.win.nativeWindow.webkit.messageHandlers !== "undefined" &&
      typeof this.win.nativeWindow.webkit.messageHandlers.buttonClicked !== "undefined"
    ) {
      this.deviceType = DeviceType.iOS;
    } else {
      this.deviceType = DeviceType.Browser;
    }

    console.log(DeviceType[this.deviceType]);
  }
}
