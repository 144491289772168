import { Component, OnInit, ViewEncapsulation, Input, OnChanges, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'bw-theme, [bw-theme]',
  templateUrl: './bw-theme.component.html',
  styleUrls: ['./bw-theme.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit, OnChanges, AfterViewInit {
  _currentTheme: string;

  @Input() theme: string;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this._currentTheme = this.theme;
  }

  ngAfterViewInit() {}

  ngOnChanges() {
    if (this.theme) {
      this.renderer.removeClass(document.body, this._currentTheme);
      this.renderer.addClass(document.body, this.theme);
      this._currentTheme = this.theme;
    }
  }
}
