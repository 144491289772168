import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ThemeComponent } from './bw-theme/bw-theme.component';
import {ButtonComponent} from './button/button.component';
import {BridgeService} from './bridge.service';
import {WindowRef} from './window.ref';

@NgModule({
  declarations: [
    AppComponent,
    ThemeComponent,
    ButtonComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [ BridgeService, WindowRef],
  bootstrap: [AppComponent]
})
export class AppModule { }
